.legal-docs-detail {
    height: 100%;
    &__loader {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__body {
        padding: 0px 20px 20px 20px;
        overflow: auto;
        height: calc(100% - 120px);
    }
    &__title {
        font-size: 16px;
        font-family: $font-default;
        font-weight: 600;
        color: var(--theme-dark-color);
    }
    &__collapse {
        background-color: var(--theme-lightgray-bg);
        &.hidden {
            display: none !important;
        }
    }
    .legal-documents-pdf {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px;
        background-color: transparent !important;
        width: 100%;
        height: 50vh;
        overflow: auto;
    }
    &__mobile-action {
       display: flex;
       justify-content: center;
       padding: 10px 0px 0px;
    }
    &__list {
        &__item {
            background-color: var(--theme-lightgray-bg);
            padding: 12px 16px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border-bottom: 1px solid #d9d9d9;
            font-size: 14px;
            font-family: $font-default;
            font-weight: 600;
            color: #000000;
            &--active {
                color: var(--theme-dark-blue-color);
                svg {
                    color: var(--theme-dark-blue-color) !important;
                }
            }
        }
        &__control {
            width: calc(100% - 25px);
            display: flex;
            svg {
                margin-right: 10px;
            }
        }
    }
    &__number {
        width: 20px;
        height: 20px;
        background-color: var(--theme-dark-color);
        color: #fff;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        &--active {
            background-color: var(--theme-default-color);
            color: #fff;
        }
    }
    &__advice {
        padding: 0px 0px 8px;
    }
}

@media (max-width: 767px) {
    .legal-docs-detail {
        .legal-documents-pdf {
            display: block;
        }
        .ant-collapse-content-box {
            padding: 0px !important;
        }
    }
}

@media (max-width: 400px) {
    .legal-docs-detail{
        &__title {
            font-size: 14px;
        }
        .base-title {
            font-size: 12px;
            line-height: 1.2em;
        }
    } 
}