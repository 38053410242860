$font-default: 'Titillium Web', sans-serif;
$shadow-color: rgba(0,0,0,0.3);
$white-color: #ffffff;
$black: #000000;

$grid-breakpoints: (
    xs: 0px,
    sm: 640px,
    md: 768px,
    lg: 1025px,
    xl: 1535px,
    xxl: 1920px,
) !default;
