@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min : map-get($breakpoints, $name);
    @media (min-width: $min) {
        @content;
    }
}

@mixin shadow-plain($x:0, $y:0, $blur:0, $color: $shadow-color) {
    -webkit-box-shadow: #{$x}px #{$y}px #{$blur}px #{$color} !important;
    -moz-box-shadow: #{$x}px #{$y}px #{$blur}px #{$color} !important;
    box-shadow: #{$x}px #{$y}px #{$blur}px #{$color} !important;
}
