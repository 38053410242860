.base {
    &-title {
        font-size: 16px;
        font-family: $font-default;
        font-weight: 600;
        color: var(--theme-dark-color);
    }
    &-label {
        font-size: 14px;
    }
}