.base-icon {
    &--active {
        font-size: 1.5em !important;
        color: var(--theme-dark-blue-color) !important;
    }
    &--default {
        color: $black !important;
        font-size: 1.5em !important;
    }
}
