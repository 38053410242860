.base{
    &-link {
        font-size: 14px;
        border: none;
        outline: none;
        box-shadow: none;
        text-decoration: underline !important;
        color: var(--theme-default-color);
        &:hover, &:active, &:focus {
            color: var(--theme-dark-blue-color);
            text-decoration: underline !important;
            border: none;
            outline: none;
            box-shadow: none;
        }
    }
}
