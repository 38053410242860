.legal-docs {
    &-app {
        position: fixed;
        z-index: 10000;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        &__loader {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 10;
        }
    }
    &-wrapper {
        max-width: map-get($grid-breakpoints,'xl');
        background-color: var(--theme-wrapper-bg);
        width: 100%;
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        @include shadow-plain(0, 8, 16);
        &--covered {
            .legal-docs-detail__body, .legal-docs-signature__body{
                opacity: 0.3;
            }
        }
    }
    &__content {
        width: 65%;
        height: 100%;
    }
    &__sidebar {
        width: calc(35% - 2px);
        border-left: 2px solid var(--theme-lightgray-border);
        position: relative;
        height: 100%;
    }
}


@include media-breakpoint-up('lg') {
    .legal-docs {
        &-wrapper {
            background-color: var(--theme-wrapper-bg);
            width: calc(100% - 64px);
            display: flex;
            flex-wrap: wrap;
            height: calc(100vh - 64px);
            @include shadow-plain(0, 8, 16);
        }
        &__content {
            width: 65%;
            height: 100%;
        }
        &__sidebar {
            width: calc(35% - 2px);
            border-left: 2px solid var(--theme-lightgray-border);
            position: relative;
            height: 100%;
        }
    }
}

@media (max-width: 1024px) {
    .legal-docs {
        &-app {
            height: 100vh;
            overflow-y: auto;
            background-color: $white-color;
            display: block;
        }
        &-wrapper {
            display: block;
            height: auto;
            box-shadow: none !important;
            width: 100%;
        }
        &__content {
            width: 100%;
        }
        &__sidebar {
            background-color: $white-color;
            width: 100%;
            height: auto;
            border: none;
            .legal-docs-header {
                padding: 0px 20px 10px;
            }           
        }
    }
}
