body {
    --theme-wrapper-bg: #ffffff;
    --theme-default-color: #005EB8; //Azul Contpaqi
    --theme-dark-color: #5E5E5E;
    --theme-dark-blue-color: #003A70;
    --theme-cyan-color: #00C0F3;
    --theme-gray-color: #7C878E;
    --theme-lightgray-bg: rgba(124,135,142, 0.05);
    --theme-lightgray-border: rgba(124,135,142, 0.1);
    --theme-default-rgb: 0, 94, 184; //Azul Contpaqi
    --theme-cyan-rgb: 0, 192, 243;
    --theme-secondary-btn-border-color: #F0F0F0;
    --theme-secondary-btn-bg-color: #ffffff;
    --theme-secondary-btn-bg-hover-color: #CCD8E2;
    --theme-secondary-btn-bg-active-color: #CCECEB;
    --theme-secondary-btn-bg-focused-color: #CCDFF1;
    --theme-secondary-btn-text-color: #003A70;
    --theme-secondary-btn-text-hover-color: #003A70;
    --theme-disabled-btn-text-color: #757575;
    --theme-disabled-link-text-color: #757575;
    --theme-disabled-btn-bg-color: #F0F0F0;
}
