.legal-docs-header {
    width: calc(100% - 40px);
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 40px;
    &__logo {
        width: 140px;
        display: flex;
        align-items: flex-end;
    }
    &__reg {
        color: var(--theme-dark-blue-color);
        font-weight: 600;
        margin-left: 2px;
        margin-bottom:-3px;
    }
    &__content {
        width: calc(100% - 140px);
        text-align: center;
        &--full {
            width: 100%;
        }
    }
}
