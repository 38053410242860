.legal-docs-signature {
    height: calc(100% - 110px);
    overflow: auto;
    &__header {
        position: sticky;
        top: 0px;
        background-color: $white-color;
        z-index: 3;
    }
    &__close {
        position: absolute;
        right: 10px;
        top: 25px;
    }
    &__content {
        padding: 0px 20px;
    }
    &__intro {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        &__message {
            padding: 20px;
            text-align: center;
        }
        img {
            max-width: 220px;
        }
    }
    &__cta {
        position: absolute;
        width: 100%;
        left: 0px;
        bottom: 0px;
        &__btn {
            display: flex;
            justify-content: center;
            padding-bottom: 20px;
            max-width: 280px;
            margin: 0px auto;
            position: relative;
            button {
                width: auto;
            }
            &--sticky {
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                max-width: 100%;
                padding: 0px;
                min-height: 50px;
                button {
                    border-radius: 0px;
                    width: 100%;
                    padding: 10px 0px;
                    font-weight: 600;
                    &:hover, &:active, &:focus {
                        box-shadow: none;
                        background-color: var(--theme-dark-blue-color) !important;
                    }
                }
            }
        }
        &__info {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: $white-color;
            position: absolute;
            z-index: 2;
            top: 8px;
            right: 20px;
        }
        &__close {
            margin-right: 30px;
        }
        &__continue {
            position: relative;
            display: flex;
            .base-btn {
                width: auto;
                padding-left: 20px;
                height: 35px;
            }
            &--with-popover {
                .base-btn {
                    width: 138px;
                    padding-left: 0px;
                }
            }
        }
    }
    &__radios {
        position: relative;
        &__info {
            position: absolute;
            right: 0px;
            top: 0px;
            font-size: 18px;
            color: var(--theme-default-color);
            z-index: 2;
        }
    }
    &__title {
        padding: 0px 0px 8px;
        &--advice {
            text-align: center;
            color: var(--theme-default-color);
        }
    }
    &__counter {
        color: var(--theme-default-color);
    }
}

@media (max-width: 1024px) {
    .legal-docs-signature {
        &__close {
            top: 10px;
            right: 0px;
        }
        &__cta {
            position: inherit;
            left: inherit;
            bottom: inherit;
        }
    }
}

@media (max-width: 767px) {
    .legal-docs-signature__radios__info {
        display: none !important;
    }
}
