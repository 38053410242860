.base-btn {
    font-family: $font-default;
    font-size: 16px;
    letter-spacing: 0.5px;
    box-shadow: none !important;
    padding: 4px 20px;
    border: none;
    background-color: var(--theme-default-color);
    color: $white-color;
    cursor: pointer;
    outline: none;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
    line-height: inherit;
    &:hover, &:active, &:focus {
        border: none;
        color: $white-color !important;
        background-color: var(--theme-dark-blue-color) !important;
        outline: none;
        @include shadow-plain(1, 3, 6);
    }
    &:disabled {
        border: none;
        color: $white-color !important;
        background-color: rgba(var(--theme-default-rgb), 0.5) !important;
        outline: none;
        cursor: not-allowed !important;
    }
    &--secondary {
        background: var(--theme-secondary-btn-bg-color) !important;
        border: 3px solid var(--theme-secondary-btn-border-color) !important;
        color: var(--theme-secondary-btn-text-color) !important;
        outline: none !important;
        &:hover, &:active, &:focus {
            background: var(--theme-secondary-btn-bg-hover-color) !important;
            border: 3px solid var(--theme-secondary-btn-border-color) !important;
            color: var(--theme-secondary-btn-text-hover-color) !important;
            outline: none !important;
        }
        &:disabled {
            background: var(--theme-disabled-btn-bg-color) !important;
            border: 3px solid var(--theme-disabled-btn-text-color) !important;
            color: var(--theme-disabled-btn-text-color) !important;
            outline: none !important;
            cursor: not-allowed;
        }
    }
}

.btn-icon {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    &:hover {
        border: none;
        background: none;
        outline: none;
        .svg-icon path{
            fill: var(--theme-default-color);
        }
    }
}
