body {
    font-size: 14px;
    font-family: $font-default;
    color: var(--theme-dark-color);
}

@media (min-width: 768px) {
    body {
        font-size: 16px;
    }
}

[class^='ant-'], [class*="ant-"] {
    color: var(--theme-dark-color);
    font-family: $font-default !important;
}

.ant-radio-wrapper {
    .ant-radio-checked::after {
        border-color: var(--theme-default-color) !important;
    }
    &-disabled {
        color: var(--theme-gray-color) !important;
    }
}
.ant-alert {
    &-success {
        background-color: rgba(var(--theme-cyan-rgb), 0.2);
        border: 1px solid var(--theme-default-color);
        .ant-alert-icon {
            color: var(--theme-default-color);
        }
        .ant-alert-message {
            color: var(--theme-dark-blue-color) !important;
        }
    }
}
.ant-popover {
    z-index: 1000000;
    max-width: 300px;
}
.ant-collapse {
    &-header {
        .legal-docs-detail__title {
            color: $black;
        }
    }
    &-item-active {
        .legal-docs-detail__title {
            color: var(--theme-dark-blue-color);
        }
    }
}
.ant-modal-root .ant-modal {
    &-mask {
        z-index: 10000 !important;
        height: 100vh;
        background-color: rgba(0,0,0,0.2);
    }
    &-wrap {
        z-index: 10001 !important;
    }
    .anticon {
        color: var(--theme-default-color);
    }
    .ant-modal-confirm-title {
        color: var(--theme-default-color);
    }
}

.ant-btn-primary {
    font-family: $font-default;
    font-size: 16px;
    background-color: var(--theme-default-color);
    color: $white-color;
    border: none;
    border-radius: 60px;
    &:hover, &:active, &:focus {
        border: none;
        color: $white-color !important;
        background-color: var(--theme-dark-blue-color) !important;
        outline: none;
        @include shadow-plain(1, 3, 6);
    }
}

.upload-list-inline .ant-upload-list-item {
    float:none;
    width: 180px;
}
.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
    float:initial;
}
.ant-notification {
    z-index: 1000000;
}
.ant-popconfirm-buttons {
    display: flex;
    justify-content: center;
    padding: 10px 0px 0px;
}

.ant-collapse-expand-icon {
    display: none !important;
}
.ant-collapse-header-text {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
}
.ant-collapse-header{
    &:hover {
        background-color: var(--theme-disabled-btn-bg-color);
    }
}